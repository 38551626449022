import React, { useState } from "react"
import styled from "styled-components"
import { TextInput, TextArea, Button, Box, ResponsiveContext } from "grommet"

import SmallDialog from "../../components/Dialogs/SmallDialog"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const WhiteButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  z-index: 2;
  height: 47px;
`

const InputArea = styled(TextArea)`
  font-weight: 500;
  font-size: 18px;
  border-radius: 12px;
`

const WhiteInput = styled(TextInput)`
  border-radius: 12px;

  height: 47px;
  font-weight: 500;
  padding-right: 40px;
`

const Contact = () => {
  const [state, setState] = useState({})
  const [dialog, setDialog] = useState(false)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    }).catch(error => alert(error))
  }

  const handleSubmitClick = () => {
    setDialog(true)
    setTimeout(function() {
      setDialog(false)
    }, 3000)
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {dialog && <SmallDialog />}
          <Box
            round="small"
            background="white"
            pad={size === "small" ? "large" : "medium"}
            style={{ boxShadow: "rgb(61, 57, 110) 0px 8px 26px" }}
          >
            <form
              name="Get To Know more about Pricing"
              method="post"
              action="/pricing/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </p>

              <Box
                direction="row"
                wrap={false}
                pad={
                  size === "small" ? { bottom: "medium" } : { bottom: "small" }
                }
              >
                <Box
                  margin={
                    size === "small" ? { right: "medium" } : { right: "small" }
                  }
                >
                  <WhiteInput
                    placeholder="Your Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <WhiteInput
                    placeholder="Your Email"
                    primary
                    type="email"
                    name="email"
                    onChange={handleChange}
                  />
                </Box>
              </Box>

              <Box>
                <InputArea
                  placeholder="Your Question"
                  primary
                  name="message"
                  onChange={handleChange}
                  resize={false}
                  size="large"
                />
              </Box>
              <Box
                pad={size === "small" ? { top: "medium" } : { top: "small" }}
              >
                <Box align="center">
                  <WhiteButton
                    primary
                    width="medium"
                    label="Contact me"
                    type="submit"
                    onClick={handleSubmitClick}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Contact
